import store from '@/store'
import dayjs from 'dayjs'
import { Response } from '../modules/request/Response'
import { getFacilityData } from './api/getFacilityData'
import { getInformationList } from './api/getInformationList'
import { getSensorData } from './api/getSensorData'
import { getSensorList } from './api/getSensorList'
import { getShopData } from './api/getShopData'
import { getTrustScore } from './api/getTrustScore'
import { getFacilityDataJson } from './json/getFacilityDataJson'
import { getInformationListJson } from './json/getInformationListJson'
import { getSensorDataJson } from './json/getSensorDataJson'
import { getSensorListJson } from './json/getSensorListJson'
import { getShopCertificateDataJson } from './json/getShopCertificateDataJson'
import { getShopCertificateListJson } from './json/getShopCertificateListJson'
import { getShopConfirmListJson } from './json/getShopConfirmListJson'
import { getShopDataJson } from './json/getShopDataJson'
import { getTrustScoreJson } from './json/getTrustScoreJson'
import { ShopDataViewModal } from './viewModel/ShopDataViewModel'
import { getAccessDecision } from './api/getAccessDecision'

export async function requestShopDetailData(id) {
  /** アクセス可否を確認する */
  const accessDecisionResponse = await getAccessDecision(id)
  if (accessDecisionResponse.isError) {
    // 取得できなければ404
    return new Response({
      status: 404,
    })
  }
  // URLのオリジナルIDを退避
  let org_id = id;
  // 取得しなおしたIDで置き換える
  id = accessDecisionResponse.data.pageUrl;

  const isLoggedIn = store.getters['auth/isLoggedIn']
  /** データの取得元がフリー公開かどうか */
  let isPublicResources = true

  let shopDataResponse = await getShopDataJson(id)

  // ログイン状態でトラスト証明書(店舗情報)JSONが取得できない場合はAPIでのリクエスト試行する
  if (isLoggedIn && shopDataResponse.isError) {
    shopDataResponse = await getShopData(id)
    isPublicResources = false
  }

  // トラスト証明書(店舗情報)が取得できなければ404
  if (shopDataResponse.isError) {
    return new Response({
      status: 404,
    })
  }

  // 最初に全てのリクエストを実行する
  /** トラスト証明書(施設情報)リクエスト */
  const facilityRequest = isPublicResources
    ? getFacilityDataJson(id)
    : getFacilityData(id)
  /** 第三者チェック情報リクエスト */
  const shopConfirmListRequest = isPublicResources
    ? getShopConfirmListJson(id)
    : null // 限定公開の場合はリクエストしない
  /** トラストスコア情報リクエスト */
  const trustScoreRequest = isPublicResources
    ? getTrustScoreJson(id)
    : getTrustScore(id)
  /** お知らせ情報リクエスト */
  const informationListRequest = isPublicResources
    ? getInformationListJson(id)
    : getInformationList(id)
  /** センサーリスト情報リクエスト */
  const sensorListRequest = isPublicResources
    ? getSensorListJson(id)
    : getSensorList(id)
  /** センサーリスト追加情報リクエスト */
  const sensorListExtraRequest =
    isLoggedIn && isPublicResources ? getSensorList(id) : null
  /** 統合認証証明書情報リクエスト */
  const shopCertificateListRequest = isPublicResources
    ? getShopCertificateListJson(id)
    : null

  const facilityResponse = await facilityRequest
  /** viewModel */
  const shopData = new ShopDataViewModal(
    shopDataResponse.data,
    facilityResponse.data,
    isPublicResources
  )

  // センサーリストのデータリクエストを作成する
  const normalizedSensorList = {
    mainSensor: null,
    sensorList: [],
  }
  let mainSensorDataRequest = null

  // センサーリストの取得を待機
  const sensorListResponse = await sensorListRequest

  if (!sensorListResponse.isError) {
    // 限定公開の場合はAPIでリクエスト
    const getSensorDataRequest = isPublicResources
      ? getSensorDataJson
      : getSensorData

    // メインセンサーが存在すればデータをリクエスト
    if (sensorListResponse.data.mainSensor) {
      normalizedSensorList.mainSensor = sensorListResponse.data.mainSensor
      mainSensorDataRequest = getSensorDataRequest(
        id,
        sensorListResponse.data.mainSensor.sensorId,
        sensorListResponse.data.roomId
      )
    }

    normalizedSensorList.sensorList = sensorListResponse.data.sensorList.map(
      (data) => ({
        isPublicResource: isPublicResources,
        ...data,
      })
    )
  }

  const sensorListExtraResponse = sensorListExtraRequest
    ? await sensorListExtraRequest
    : null

  if (sensorListExtraResponse && !sensorListExtraResponse.isError) {
    // メインセンサーが存在すればデータをリクエスト
    if (
      !normalizedSensorList.mainSensor &&
      sensorListExtraResponse.data.mainSensor
    ) {
      normalizedSensorList.mainSensor = sensorListExtraResponse.data.mainSensor
      mainSensorDataRequest = getSensorData(
        id,
        sensorListExtraResponse.data.mainSensor.sensorId,
        sensorListExtraResponse.data.mainSensor.roomId
      )
    }

    normalizedSensorList.sensorList = [
      ...normalizedSensorList.sensorList,
      ...sensorListExtraResponse.data.sensorList.map((data) => ({
        isPublicResource: false,
        ...data,
      })),
    ]
  }
  // センサーリストは登録日順にソート
  normalizedSensorList.sensorList = sensorListSort(normalizedSensorList.sensorList)
  shopData.sensorList = normalizedSensorList

  // 統合認証証明書のデータリクエストを作成する
  const shopCertificateListResponse = shopCertificateListRequest
    ? await shopCertificateListRequest
    : null
  let shopCertificateDataRequest

  if (shopCertificateListResponse && !shopCertificateListResponse.isError) {
    shopCertificateDataRequest = Promise.all(
      shopCertificateListResponse.data.certificateList.map(
        ({ verificationDate }) =>
          getShopCertificateDataJson(id, verificationDate)
      )
    )
  }

  const shopConfirmListResponse = shopConfirmListRequest
    ? await shopConfirmListRequest
    : null
  const trustScoreResponse = await trustScoreRequest
  const mainSensorDataResponse = mainSensorDataRequest
    ? await mainSensorDataRequest
    : null
  const informationListResponse = await informationListRequest

  if (shopConfirmListResponse && !shopConfirmListResponse.isError) {
    shopData.shopConfirmListData = shopConfirmListResponse.data
  }
  if (!trustScoreResponse.isError) {
    shopData.trustScoreData = trustScoreResponse.data
  }
  if (!informationListResponse.isError) {
    shopData.informationListData = informationListResponse.data
  }

  if (mainSensorDataResponse && !mainSensorDataResponse.isError) {
    shopData.mainSensorData = {
      sensorId: mainSensorDataResponse.data.sensorId,
      roomId: mainSensorDataResponse.data.roomId,
      dataList: mainSensorDataResponse.data.sensorData,
    }
  }

  if (shopCertificateDataRequest) {
    shopData.shopCertificateDataList = (await shopCertificateDataRequest)
      .filter((response) => !response.isError)
      .map(({ data }) => {
        return data
      })
  }

  return new Response({
    status: 200,
    data: shopData,
  })
}
function sensorListSort(list) {
  return list.sort((a, b) => {
    // return dayjs(a.sensorResistDateTime).isAfter(b.sensorResistDateTime) ? -1 : 1
    return dayjs(a.sensorResistDateTime).isBefore(b.sensorResistDateTime) ? -1 : 1
  })
}