import { getJsonFile } from './getJsonFile'

/**
 * 公開JSON取得APIからトラストスコア情報データを取得する
 * @param {string} id
 * @returns {Promise<import('@/assets/modules/request/ApiResponse').ApiResponse<import('@/assets/request/types/trustScore').TrustScoreApi>>}
 */
export function getTrustScore(id) {
  return getJsonFile(id, 'TS')
}
