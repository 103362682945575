import { requestApi } from '@/assets/modules/request'
import store from '@/store'

/**
 * セキュリティコードをアカウントに紐づける
 * @param {string} facilityId
 * @param {string} sectionId
 * @param {string} roomId
 * @param {string} securityCode
 * @returns {Promise<import('@/assets/modules/request/ApiResponse').ApiResponse>}
 */
export function postSecurityCode(facilityId, sectionId, roomId, securityCode) {
  return requestApi.post(
    '/securityUsers',
    {
      facilityId,
      sectionId,
      roomId,
      securityCode,
    },
    {
      headers: {
        Authorization: store.getters['auth/idToken'],
      },
    }
  )
}
