import { requestJson } from '@/assets/modules/request'

const PortalIdFilenameMap = {
  shop: 'shopList',
  school: 'schoolList',
  hospital: 'hospitalList',
}

/**
 * 店舗一覧のJSONデータを取得する
 * @param {string} portalId
 * @returns {Promise<import('@/assets/modules/request/Response').Response<import('@/assets/request/types/shoplist').ShopListDataOriginal[]>>}
 */
export function getShopListJson(portalId) {
  return requestJson.get(`/${PortalIdFilenameMap[portalId]}.json`)
}
