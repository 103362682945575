import { getJsonFile } from './getJsonFile'

/**
 * 公開JSON取得APIからお知らせ情報データを取得する
 * @param {string} id
 * @returns {Promise<import('@/assets/modules/request/ApiResponse').ApiResponse<import('@/assets/request/types/informationList').InformationListOriginal>>}
 */
export function getInformationList(id) {
  return getJsonFile(id, 'O')
}
