import { requestJson } from '@/assets/modules/request'
import dayjs from 'dayjs'

/**
 * 統合認証証明書リスト情報を取得する
 * JSON名: '301V' + {id} + '_' + {日付} + '.json'
 * @param {string} id
 * @param {string} timestamp
 * @returns {Promise<import('@/assets/modules/request/Response').Response<import('@/assets/request/types/shopCertificateData').ShopCertificateDataOriginal>>}
 */
export function getShopCertificateDataJson(id, _timestamp) {
  const timestamp = dayjs(_timestamp).format('YYYYMMDDHHmmss')
  return requestJson.get(`/301V${id}_${timestamp}.json`)
}
