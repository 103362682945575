import { Response } from '../modules/request/Response'
import { getSensorCountListJson } from './json/getSensorCountListJson'


export async function requestTotalSensorData() {

  const jsonRequest = getSensorCountListJson()
  const jsonResponse = await jsonRequest

  return new Response({
    status: 200,
    data: jsonResponse
  })
}
