import store from '@/store'
import dayjs from 'dayjs'
import { Response } from '../modules/request/Response'
import { getShopList } from './api/getShopList'
import { getShopListJson } from './json/getShopListJson'
import { ShopListViewModel } from './viewModel/ShopListViewModel'

const PortalIdKeyMap = {
  shop: 'shopList',
  school: 'schoolList',
  hospital: 'hospitalList',
}

export async function requestIndexData(portalId = 'shop') {
  const dataKey = PortalIdKeyMap[portalId]
  const isLoggedIn = store.getters['auth/isLoggedIn']
  let listData = []

  const jsonRequest = getShopListJson(portalId)
  const apiRequest = isLoggedIn ? getShopList(portalId) : null

  const jsonResponse = await jsonRequest
  if (!jsonResponse.isError) {
    listData = listSort(jsonResponse.data[dataKey])
  }

  // apiRequestを実行していてエラーでなければ
  const apiResponse = isLoggedIn ? await apiRequest : null
  if (apiResponse && !apiResponse.isError) {
    const apiListData = listSort(apiResponse.data[portalId])
    listData = [...apiListData, ...listData]
  }

  return new Response({
    status: 200,
    data: new ShopListViewModel(listData),
  })
}

function listSort(list) {
  // return list.sort((a, b) => {
  //   return dayjs(a.updateDateTime).isAfter(b.updateDateTime) ? -1 : 1
  // })
  for (let i = list.length - 1; i > 0; i--) {
    let r = Math.floor(Math.random() * (i + 1))
    let tmp = list[i]
    list[i] = list[r]
    list[r] = tmp
  }
  // console.log(list)
  return list
}
