export class ShopListViewModel {
  constructor(data) {
    this.data = data
  }

  /** @returns {import('@/assets/request/types/shoplist').ShopListData[]} */
  get list() {
    return this.data.map((item) => {
      /** @type {import('@/assets/request/types/shoplist').ShopListDataOriginal} */
      const {
        shopName,
        shopNameEnglish,
        facilityName,
        facilityNameEnglish,
        pageUrl,
        shopImageUrl,
        iotCheckFlag,
        thirdPersonCheckFlag,
        trustScore,
        updateDateTime,
        englishPageCreateFlag,
        address,
        addressEnglish,
      } = item

      return {
        id: pageUrl,
        shopName,
        shopNameEnglish,
        facilityName,
        facilityNameEnglish,
        thumbnail: shopImageUrl,
        isIotCheck: iotCheckFlag === '1',
        isThirdPersonCheck: thirdPersonCheckFlag === '1',
        trustIndex: trustScore || 0,
        updatedAt: updateDateTime,
        hasEnglishPage: englishPageCreateFlag === '0' ? false : true,
        address,
        addressEnglish,
      }
    })
  }
}
