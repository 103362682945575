import { requestApi } from '@/assets/modules/request'
import store from '@/store'

/**
 * 店舗一覧APIからデータを取得する
 * @param {string} portalId
 * @returns {Promise<import('@/assets/modules/request/ApiResponse').ApiResponse<import('@/assets/request/types/shoplist').ShopListDataApi>>}
 */
export function getShopList(portalId) {
  return requestApi.get('/releases', {
    headers: {
      Authorization: store.getters['auth/idToken'],
    },
    params: {
      portalId,
    },
  })
}
