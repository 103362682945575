import dayjs from 'dayjs'

export class ShopDataViewModal {
  constructor(shopData, facilityData, isPublicResources) {
    /** @type {import('@/assets/request/types/shopData').ShopDataOriginal} */
    this._shopData = shopData
    /** @type {import('@/assets/request/types/facilityData').FacilityDataOriginal} */
    this._facilityData = facilityData
    this.isPublicResources = isPublicResources
    /** @type {import('@/assets/request/types/shopConfirmList').ShopConfirmListOriginal | null} */
    this._shopConfirmListData = null
    /** @type {import('@/assets/request/types/trustScore').TrustScoreOriginal | null} */
    this._trustScoreData = null
    /** @type {import('@/assets/request/types/informationList').InformationListOriginal | null} */
    this._informationListData = null
    /** @type {import('@/assets/request/types/sensorList').SensorListOriginal} */
    this._sensorList = null
    /** @type {{ id: import('./SensorDataViewModel').SensorDataViewModel['id'], dataList: import('./SensorDataViewModel').SensorDataViewModel['sensorData'] }} */
    this._mainSensorData = null
    /** @type {import('@/assets/request/types/shopCertificateData').ShopCertificateDataOriginal[] } */
    this._shopCertificateDataList = []
  }

  get shopData() {
    return this._shopData.certificateTable[0].certificateInfo
  }

  get facilityData() {
    return this._facilityData.certificateTable[0].certificateInfo
  }

  get shopConfirmListData() {
    return this._shopConfirmListData
  }

  set shopConfirmListData(shopConfirmListData) {
    this._shopConfirmListData = shopConfirmListData
  }

  get trustScoreData() {
    return this._trustScoreData
  }

  set trustScoreData(trustScoreData) {
    this._trustScoreData = trustScoreData
  }

  get informationListData() {
    return this._informationListData
  }

  set informationListData(informationListData) {
    this._informationListData = informationListData
  }

  get sensorList() {
    return this._sensorList
  }

  set sensorList(sensorList) {
    this._sensorList = sensorList
  }

  get mainSensorData() {
    return this._mainSensorData
  }

  set mainSensorData(mainSensorData) {
    this._mainSensorData = mainSensorData
  }

  get shopCertificateDataList() {
    return this._shopCertificateDataList
  }

  set shopCertificateDataList(shopCertificateDataList) {
    this._shopCertificateDataList = shopCertificateDataList
  }

  get shop() {
    const {
      shopName,
      shopNameEnglish,
      shopAddress,
      shopAddressEnglish,
      shopUrl,
      shopInfoUpdateDate,
      shopImageUrl,
      videoUrl,
    } = this.shopData.extendedItemsArea
    const {
      freeDescription,
      freeDescriptionEnglish,
    } = this.shopData.abilityInfo

    return {
      name: shopName,
      nameEnglish: shopNameEnglish,
      address: shopAddress,
      addressEnglish: shopAddressEnglish,
      siteUrl: shopUrl,
      comment: freeDescription,
      commentEnglish: freeDescriptionEnglish,
      thumbnail: shopImageUrl || '',
      youtubeVideoId: videoUrl || '',
      updatedAt: shopInfoUpdateDate,
    }
  }

  get facility() {
    const {
      facilityName,
      facilityNameEnglish,
    } = this.shopData.extendedItemsArea

    if (!facilityName && !facilityNameEnglish) {
      return null
    }

    return {
      name: facilityName,
      nameEnglish: facilityNameEnglish,
    }
  }

  get surveyInfo() {
    const { enqueteList } = this.shopData.extendedItemsArea

    if (!enqueteList) {
      return []
    }

    return enqueteList.map(({ question, answerFormat, publishFlag }) => ({
      question,
      format: answerFormat,
      published: publishFlag === '1',
    }))
  }

  get organization() {
    const {
      organization,
      organizationEnglish,
      organizationUrl,
    } = this.facilityData.extendedItemsArea

    return {
      name: organization,
      nameEnglish: organizationEnglish,
      siteUrl: organizationUrl,
    }
  }

  get measures() {
    const {
      ventilationRunning,
      disinfectShopFacility,
      disinfectShopTable,
      customerCisinfect,
      customerThermometry,
      tableDistance,
      staffThermometry,
      staffMask,
      disinfectRestroom,
      certifiedStoreMark,
    } = this.shopData.abilityInfo
    const {
      ventilationVolume,
      ventilationRunning: facilityVentilationRunning,
      disinfectRestroom: facilityDisinfectRestroom,
    } = this.facilityData.abilityInfo

    return {
      shop: {
        ventilation: {
          running: getMeasureStatus(ventilationRunning),
        },
        disinfect: {
          facility: getMeasureStatus(disinfectShopFacility),
          table: getMeasureStatus(disinfectShopTable),
        },
        customer: {
          disinfect: getMeasureStatus(customerCisinfect),
          thermometry: getMeasureStatus(customerThermometry),
        },
        distance: {
          table: getMeasureStatus(tableDistance),
        },
        staff: {
          healthManagement: getMeasureStatus(staffThermometry),
          wearingMask: getMeasureStatus(staffMask),
        },
        restroom: {
          disinfect: getMeasureStatus(disinfectRestroom),
        },
        certification: {
          mark: getMeasureStatus(certifiedStoreMark),
        },
      },
      facility: {
        ventilation: {
          volume: getMeasureStatus(ventilationVolume),
          running: getMeasureStatus(facilityVentilationRunning),
        },
        restroom: {
          disinfect: getMeasureStatus(facilityDisinfectRestroom),
        },
      },
    }
  }

  get measureImageList() {
    const list = this.shopData.extendedItemsArea.ablitityInfoImage
    if (!Array.isArray(list)) return []

    return list.map(({ imageCode, imageUrl }) => ({
      category: getMeasureCategory(imageCode),
      src: imageUrl,
    }))
  }

  get shopConfirmList() {
    return this.shopConfirmListData
      ? this.shopConfirmListData.shopConfirmList.map(
          ({ organization, organizationEnglish, shopConfirmUpdateDate }) => ({
            organization,
            organizationEnglish,
            confirmedAt: shopConfirmUpdateDate,
          })
        )
      : []
  }

  get trustIndex() {
    return this.trustScoreData ? this.trustScoreData.trustScore : 0
  }

  get informationList() {
    return this.informationListData
      ? this.informationListData.informationList
          .map(
            ({
              informationId,
              releaseStartDate,
              informationTitle,
              informationMessage,
            }) => ({
              id: informationId,
              publishedAt: releaseStartDate,
              title: informationTitle,
              content: informationMessage,
            })
          )
          .sort((a, b) =>
            dayjs(a.publishedAt).isBefore(b.publishedAt) ? 1 : -1
          )
      : []
  }

  get mainSensor() {
    return this.mainSensorData
      ? {
          ...this.mainSensorData,
          name: this.sensorList.mainSensor.sensorName,
          nameEnglish: this.sensorList.mainSensor.sensorNameEnglish,
        }
      : null
  }

  get sensorMetaList() {
    if (!this.sensorList) {
      return []
    }

    return this.sensorList.sensorList
  }

  get certificateList() {
    return this.shopCertificateDataList.map(
      ({ numberOfPeople, certificateTable }) => {
        const {
          elementId,
          extendedItemsArea,
          abilityInfo,
        } = certificateTable[0].certificateInfo
        const id = elementId.replace(/.*_(.*)/, '$1')
        const { shopName } = extendedItemsArea
        const { healthCheck, verificationDate } = abilityInfo

        return {
          id,
          shopName,
          count: numberOfPeople,
          checkLabel: healthCheck,
          certificatedAt: verificationDate,
        }
      }
    )
  }

  get hasEnglishPage() {
    return this.shopData.extendedItemsArea.englishPageCreateFlag === '0'
      ? false
      : true
  }
}

function getMeasureStatus(value) {
  return value === '1'
}

function getMeasureCategory(value) {
  switch (value) {
    case '01':
      return 'ventilation'
    case '02':
      return 'disinfect'
    case '03':
      return 'customer'
    case '04':
      return 'distance'
    case '05':
      return 'staff'
    case '06':
      return 'restroom'
    case '07':
      return 'certification'
  }
}
