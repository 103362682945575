import { requestJson } from '@/assets/modules/request'

/**
 * お知らせ情報を取得する
 * JSON名: 'O' + {id} + '.json'
 * @param {string} id
 * @returns {Promise<import('@/assets/modules/request/Response').Response<import('@/assets/request/types/informationList').InformationListOriginal>>}
 */
export function getInformationListJson(id) {
  return requestJson.get(`/O${id}.json`)
}
