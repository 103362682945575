import { requestJson } from '@/assets/modules/request'

/**
 * 統合認証証明書リスト情報を取得する
 * JSON名: '301V' + {id} + '.json'
 * @param {string} id
 * @returns {Promise<import('@/assets/modules/request/Response').Response<import('@/assets/request/types/shopCertificateList').ShopCertificateListOriginal>>}
 */
export function getShopCertificateListJson(id) {
  return requestJson.get(`/301V${id}.json`)
}
