import { requestJson } from '@/assets/modules/request'

/**
 * 区画情報を取得する
 * JSON名: 'K' + {id} + '.json'
 * @param {string} id
 * @returns {Promise<import('@/assets/modules/request/Response').Response<import('@/assets/request/types/facilityData').FacilityDataOriginal>>}
 */
export function getFacilityDataJson(id) {
  return requestJson.get(`/K${id}.json`)
}
