import { requestJson } from '@/assets/modules/request'

/**
 * トラストスコア情報を取得する
 * JSON名: 'TS' + {id} + '.json'
 * @param {string} id
 * @returns {Promise<import('@/assets/modules/request/Response').Response<import('@/assets/request/types/trustScore').TrustScoreOriginal>>}
 */
export function getTrustScoreJson(id) {
  return requestJson.get(`/TS${id}.json`)
}
